<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('navbar.user_center') }}</div>
                <el-button type="danger" icon="el-icon-switch-button" plain @click="onLogout()">{{ $t('navbar.logout') }}</el-button>
            </div>
        </el-card>
        <el-row :gutter="20" class="mb-50 color-b">
            <el-col :span="5">
                <div
                    v-for="(item, index) in navMenu" :key="index"
                    class="bg-white box-shadow border-r p-20 is-link menu-item d-flex-center"
                    :class="{ active: $route.path == item.route || $route.name == item.name}"
                    @click="onNav(item.route)"
                >
                    <i class="mr-10" :class="item.icon" />
                    <span class="mr-auto" v-text="item.name" />
                    <i class="el-icon-arrow-right" />
                </div>
            </el-col>
            <el-col :span="19">
                <router-view />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            navMenu: [
                {
                    icon: 'el-icon-user',
                    name: this.$t('account.profile'),
                    route: '/user/account/profile'
                },
                {
                    icon: 'el-icon-share',
                    name: this.$t('account.invite_friends'),
                    route: '/user/account/share'
                },
                {
                    icon: 'el-icon-tickets',
                    name: this.$t('account.transactions'),
                    route: '/user/account/transactions'
                },
                {
                    icon: 'el-icon-tickets',
                    name: this.$t('account.options_orders'),
                    route: '/user/account/options'
                },
                // {
                //     icon: 'el-icon-share',
                //     name: this.$t('account.inviting_and_awards'),
                //     route: '/user/account/inviting'
                // },
                {
                    icon: 'el-icon-postcard',
                    name: this.$t('account.kyc_verify'),
                    route: '/user/account/verify'
                },
                {
                    icon: 'el-icon-lock',
                    name: this.$t('account.change_password'),
                    route: '/user/account/password'
                },
                {
                    icon: 'el-icon-tickets',
                    name: this.$t('account.login_logs'),
                    route: '/user/account/login-logs'
                }
            ]
        }
    },
    methods: {
        onLogout: function () {
            this.$confirm('Are you sure you want to logout of this account ?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(async() => {
                this.$request.get('logout')
                this.$store.commit('userToken', '')
                this.$store.commit('userId', '')
                this.$nav.replace('/')
            })
            
            
        },
        onNav: function (route){
            if (!route) return
            if (this.$route.path == route) return
            this.$nav.replace(route)
        }
    }
}
</script>
<style lang="scss" scoped>
.menu-item{
    border-left: 4px solid transparent;
}
.menu-item.active,
.menu-item:hover{
    border-left: 4px solid $--color-primary;
    color: $--color-primary;
}
</style>